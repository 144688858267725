@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.brand {
  color: black;
  font-family: 'Orbitron', sans-serif;
}

.coinsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  color: #fff;
}

.header {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.inputField {
  padding-left: 16px;
  font-size: 14px;
  width: 260px;
  height: 42px;
  border: 0;
  color: black;
  border-bottom: 1px solid black;
}

input:focus {
  outline:none !important;
}

@media (max-width: 520px) {
  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1.brand {
    padding-bottom: 16px;
  }
}

* {
    background-color: white;
}

.cryptoCoin {
    overflow: hidden;
    text-align: center;
    border-radius: 1rem;
    position: relative;
    width: 320px;
    margin: 2rem;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    color: black;
    border: 1px solid black; /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
    transition: all .5s ease;
}

.cryptoCoin:hover { /* Safari and Chrome */ /* Firefox */ /* IE 9 */ /* Opera */
    transform:scale(1.20);
}

.coinLogo {
    width: 8rem;
    margin-top: 1.2rem;
    height: 40px;
    width: 40px;
    -webkit-clip-path: circle(60px at center);
            clip-path: circle(60px at center);
    color: black;
}

.coinNameWrap {
    padding: 4px;
    font-weight: 700;
    color: black;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.coinName {
    font-weight: bold;
    font-size: 1.5rem;
    color: black;
}

.coinSymbol {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
    padding-left: 8px;
}

.priceContainerUP {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    font-size: 1.2rem;
    color: black;
    padding: 1rem;
    background-color: #11d811;
}

.priceContainerDOWN {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: none;
    font-size: 1.2rem;
    color: black;
    padding: 1rem;
    background-color: #f00606;
}

svg {
    background-color: transparent;
}

.priceChange {
    background-color: transparent;
    padding-left: 10px;
    text-shadow: 0 0 14px black;
    color: white;
}

.coinPrice {
    width: auto;
    background-color: transparent;
    color: black;
}

.coinMarketcap {
    width: auto;
    padding-top: 8px;
    background-color: transparent;
    color: black;
}

.coinVolume {
    width: auto;
    padding-top: 6px;
    padding-bottom: 8px;
    background-color: transparent;
    color: black;
}

