@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
  color: #fff;
}

.brand {
  color: black;
  font-family: 'Orbitron', sans-serif;
}

.coinsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  color: #fff;
}

.header {
  display: flex;
  justify-content: space-around;
  margin-top: 40px;
}

.inputField {
  padding-left: 16px;
  font-size: 14px;
  width: 260px;
  height: 42px;
  border: 0;
  color: black;
  border-bottom: 1px solid black;
}

input:focus {
  outline:none !important;
}

@media (max-width: 520px) {
  .header {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  h1.brand {
    padding-bottom: 16px;
  }
}
